import React from "react"
import { Link } from "gatsby"

function BrandLogo() {
  const fillColorClass = "#1E2C5C"
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.92 90.23">
      <g>
        <g>
          <g>
            <polygon fill={fillColorClass} strokeWidth="0" points="41.61 48.61 35.36 42.36 0 77.71 0 90.23 41.61 48.61"/>
            <polygon fill={fillColorClass} strokeWidth="0" points="41.61 27.44 35.36 21.18 0 56.53 0 69.05 41.61 27.44"/>
            <polygon fill={fillColorClass} strokeWidth="0" points="41.61 6.26 35.36 0 0 35.35 0 47.87 41.61 6.26"/>
          </g>
          <g>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M68.77,24.95h21.42v2.61h-14.6v15.66h13.76v2.67h-13.76v17.09h14.82v2.61h-21.65V24.95Z"/>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M127.6,24.95l-14.32,41.74-15.38-41.74h7.13s10.07,26.83,10.07,26.83l9.26-26.83h3.23Z"/>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M135.04,24.95h21.42v2.61h-14.6v15.66h13.76v2.67h-13.76v17.09h14.82v2.61h-21.65V24.95Z"/>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M202.82,24.95h15.66c8.5,0,10.51,5.46,10.51,11,0,2.95-1.12,6.18-2.91,7.84-1.61,1.45-3.87,2.38-6.78,2.8l9.61,19.02h-7.47s-9.9-20.83-9.9-20.83h.45c3.17,0,5.57-.68,7.21-2.05,1.83-1.49,2.74-3.73,2.74-6.71,0-2.61-.6-4.68-1.79-6.21-1.45-1.91-3.73-2.86-6.82-2.86h-3.69v38.65h-6.83V24.95Z"/>
            <path fill={fillColorClass} strokeWidth="0" d="M238.84,24.95h6.82v40.64h-6.82V24.95Z"/>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M266.18,63.54c1.68,0,3.18-.66,4.5-1.99,1.32-1.32,1.99-3,1.99-5.03,0-3.22-1.46-5.7-4.37-7.21-2.8-1.45-5.36-2.65-8.11-4.39-3.05-1.92-4.52-5.09-4.52-9.19,0-3.31,1.1-6.12,3.3-8.44,2.2-2.32,4.98-3.48,8.33-3.48,2.31,0,4.46.65,6.46,1.94,2,1.3,3.48,3.06,4.45,5.28l-2.35,1.73c-.75-1.76-1.68-3.11-2.8-4.06-1.3-1.11-2.85-1.66-4.64-1.66s-3.27.67-4.33,2.02c-1.06,1.34-1.59,3.03-1.59,5.06,0,2.56,1.27,4.49,4.21,6.01,2.57,1.33,5.64,2.89,8.38,4.66,3.06,1.98,4.41,5.37,4.41,9.5,0,3.64-1.18,6.62-3.55,8.93-2.37,2.32-5.15,3.47-8.36,3.47-2.65,0-4.85-.45-6.6-1.37-1.98-1.04-3.58-2.69-4.81-4.97l2.52-2.05c1.01,1.78,2.07,3.1,3.19,3.95,1.12.85,2.55,1.27,4.3,1.27Z"/>
            <path fill={fillColorClass} strokeWidth="0"
                  d="M288.28,24.95h21.42v2.61h-14.59v15.66h13.76v2.67h-13.76v17.09h14.82v2.61h-21.64V24.95Z"/>
          </g>
          <path fill={fillColorClass} strokeWidth="0"
                d="M166.47,24.95h15.66c8.5,0,10.52,5.46,10.52,11,0,2.95-1.12,6.18-2.91,7.84-1.61,1.45-3.87,2.38-6.78,2.8l9.61,19.02h-7.47s-9.9-20.83-9.9-20.83h.45c3.17,0,5.57-.68,7.21-2.05,1.83-1.49,2.74-3.73,2.74-6.71,0-2.61-.6-4.68-1.79-6.21-1.46-1.91-3.73-2.86-6.82-2.86h-3.69v38.65h-6.83V24.95Z"/>
        </g>
      </g>
    </svg>
  )
}

function GlobalHeader() {
  return (
    <header className="w-full bg-white flex top-0 left-0 z-[19]">
      <div
        className={"w-full min-h-common-header max-h-common-header px-4 py-1 lg:py-2 bg-white flex justify-between items-center"}>
        <Link to={"https://www.ever-rise.co.jp/"} target={"_self"} className={"w-[70px] h-[20px]"}><BrandLogo /></Link>
        <div className={"flex space-x-3 lg:space-x-6 font-medium text-sp-common-header lg:text-main-common-header text-body-black"}>
          <Link to={"https://www.ever-rise.co.jp/services/"} target={"_blank"}
                className={"hover:underline"}>サービス</Link>
          <Link to={"https://www.ever-rise.co.jp/products/"} target={"_blank"}
                className={"hover:underline"}>プロダクト</Link>
          <Link to={"https://www.ever-rise.co.jp/company/"} target={"_blank"}
                className={"hover:underline"}>会社情報</Link>
          <Link to={"https://www.ever-rise.co.jp/news/"} target={"_blank"} className={"hover:underline"}>ニュース</Link>
        </div>
      </div>
    </header>
  )
}

export default GlobalHeader
